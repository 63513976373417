import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/logo.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h2>Step up</h2>
        <img src={logo} alt="logo" height="300" width="400" />
        <h3>80 Glen St. Glens Falls, NY</h3>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a href="https://my.rhinofit.ca/store/index/b0b816bb">Pricing</a>
        </li>
        <li>
          <a href="https://my.rhinofit.ca/students/">Member app</a>
        </li>
        <li>
          <a href=" https://my.rhinofit.ca/cal/b0b816bb?show=classes&agenda=agendaWeek&minTime=0&maxTime=24&classes=all">
            Cycling
          </a>
        </li>

        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
